.cardHeader {
  width: 100%;
  height: 64px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 64px;
  grid-template-areas: "title buttons";
}

.cardTitle {
  grid-area: title;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 64px;
  font-size: 24px;
  user-select: none;
}

.cardHeader .cardHeaderButtons {
  grid-area: buttons;
}

.readOnlyBooleanCard,
.readOnlyFloatCard,
.readWriteBooleanCard {
  width: 100%;
  height: calc(100% - 64px);
}