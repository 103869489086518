@font-face {
  font-family: Roboto;
  src: url(./Roboto-Regular.ttf);
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Segoe UI, sans-serif;
  -ms-content-zooming: none;
  touch-action: none;
  overscroll-behavior: none;
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.main {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: [start] 360px [mid] calc(100vw - 360px) [end];
  grid-template-areas: "sidebarHeader mainBodyHeader" "sidebar mainBody";
}

@media screen and (max-width: 1080px) {
  .main {
    grid-template-columns:
      [start] max(300px, 33%) [mid] min(67%, calc(100% - 300px))
      [end];
  }
}

.sidebar {
  grid-area: sidebar;
  box-sizing: border-box;
  overflow-y: auto;
}

.collectionBodyGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 256px);
  grid-auto-rows: 192px;
  grid-gap: 16px;
  justify-content: center;
}

@media only screen and (max-width: 832px) {
  .collectionBodyGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 384px));
    grid-auto-rows: 192px;
    grid-gap: 16px;
    justify-content: center;
  }
}

@media only screen and (max-width: 560px) {
  .collectionBodyGrid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 384px));
    grid-auto-rows: 192px;
    grid-gap: 16px;
    justify-content: center;
  }
}

.notSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.invisibleHeader {
  grid-row: start / mid;
  grid-column: start / end;
  background: transparent;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.2) -1px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.defaultCursor {
  cursor: default;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.plotHighlight {
  transform: translateY(10px);
}

p > section {
  height: 100%;
}

input[type="text"]::-ms-clear {
  display: none;
}

input[type="password"]::-ms-reveal {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Temporary fixes for DateRangePicker styling limitations */
.css-17vdz66 {
  width: 100%;
}

.css-1bx5ylf {
  cursor: default;
}
